<template>
  <v-container class="pa-0 fill-height d-flex align-center justify-center">
    <v-card class="ma-4 overflow-hidden" max-width="500">
      <!-- Card Image Header -->
      <v-img
        src="@/assets/alert.svg"
        height="200px"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)"
      >
       
      </v-img>

        <v-card-title class="headline font-weight-bold">
          Pago Fallido
        </v-card-title>

        <v-divider/>
      <!-- Card Content -->
      <v-card-text class="body-1 pa-4">
        <p class="text-center" style="font-size: 18px; line-height: 1.6;">
          El pago no fue procesado con éxito, por favor intente nuevamente.
        </p>

        <!-- Retry Payment Button -->
        <v-btn
          
          class="my-4"
          color="primary"
          dark
          large
          block
          @click="$router.push('/checkout')"
        >
          <v-icon left>mdi-reload</v-icon>
          Reintentar Pago
        </v-btn>

        <v-divider></v-divider>

        <p class="text-center mt-4" style="font-size: 18px; line-height: 1.6;">
          Si el problema persiste, por favor contacte al administrador.
        </p>

        <!-- WhatsApp Contact Button -->
        <v-btn
          
          class="my-4"
          color="green darken-1"
          dark
          large
          block
          href="https://wa.me/+59898996900?text=Hola!%20Tengo%20problemas%20con%20mi%20pago"
          target="_blank"
        >
          <v-icon left>mdi-whatsapp</v-icon>
          Contacto WhatsApp
        </v-btn>

        <!-- Back to Home Button -->
        <v-btn
          
          class="my-4"
          dark
          large
          block
          @click="$router.push('/inicio')"
        >
          <v-icon left>mdi-home</v-icon>
          Volver a Inicio
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.v-card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.v-card-text p {
  margin: 0;
}
</style>
